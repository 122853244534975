import React, { useEffect, useRef, useState, useMemo } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const Player = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [isReady, setIsReady] = useState(false);

  // Memoized video URL
  const videoUrl = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return decodeURIComponent(params.get('url') || 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8');
  }, []);

  // Memoized player options with optimized settings
  const videoJsOptions = useMemo(() => ({
    autoplay: true,
    muted: true,
    controls: false,
    responsive: true,
    fluid: true,
    loadingSpinner: true,
    preload: 'auto',
    poster: '', // Empty poster to prevent unnecessary image load
    sources: [{
      src: videoUrl,
      type: 'application/x-mpegURL'
    }],
    html5: {
      hls: {
        enableLowInitialPlaylist: true,
        smoothQualityChange: true,
        overrideNative: true,
        maxMaxBufferLength: 30,
      },
      nativeAudioTracks: false,
      nativeVideoTracks: false,
    },
    techOrder: ['html5'],
    playbackRates: [1],
  }), [videoUrl]);

  // Initialize player
  useEffect(() => {
    if (!videoRef.current) return;

    // Cleanup previous instance
    if (playerRef.current) {
      playerRef.current.dispose();
      playerRef.current = null;
    }

    const player = videojs(videoRef.current, videoJsOptions, () => {
      console.log('Player initialized');
      playerRef.current = player;

      // Enable inline playback for mobile
      const techEl = player.tech({ IWillNotUseThisInPlugins: true }).el();
      techEl.setAttribute('playsinline', true);
      techEl.setAttribute('webkit-playsinline', true);
      techEl.crossOrigin = 'anonymous';

      // Optimize buffering
      const videoElement = player.tech({ IWillNotUseThisInPlugins: true }).el();
      if (videoElement) {
        videoElement.preload = 'auto';
      }

      // Performance monitoring
      const startTime = Date.now();
      let firstFrameReceived = false;

      const handlers = {
        error: (error) => console.error('Video player error:', error),
        waiting: () => console.log('Video buffering...'),
        playing: () => {
          if (!firstFrameReceived) {
            firstFrameReceived = true;
            const loadTime = Date.now() - startTime;
            console.log(`First frame received in ${loadTime}ms`);
          }
          console.log('Video playing');
        },
        loadedmetadata: () => console.log('Metadata loaded'),
        loadeddata: () => console.log('Data loaded'),
        canplay: () => console.log('Can play'),
        canplaythrough: () => console.log('Can play through'),
      };

      // Add all event listeners
      Object.entries(handlers).forEach(([event, handler]) => {
        player.on(event, handler);
      });

      setIsReady(true);

      // Return cleanup function
      return () => {
        Object.entries(handlers).forEach(([event, handler]) => {
          player.off(event, handler);
        });
      };
    });
  }, [videoJsOptions]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  // Performance optimized render
  return (
    <div className="player-wrapper relative w-full h-full">
      <div
        data-vjs-player
        className="absolute inset-0"
        style={{
          contain: 'strict',
          willChange: 'transform',
        }}
      >
        <video
          ref={videoRef}
          className="video-js vjs-big-play-button-centered w-full h-full"
          playsInline
          preload="auto"
          style={{
            transform: 'translateZ(0)',
            WebkitTransform: 'translateZ(0)',
            backfaceVisibility: 'hidden',
          }}
        />
      </div>
      {!isReady && (
        <div style={{ backgroundColor: "black", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, }}>
        </div>
      )}
    </div>
  );
};

// Use React.memo to prevent unnecessary re-renders
export default React.memo(Player);