import Player from "./player/Player";
import './App.css';

const App = () => {
  return (
    <Player />
  );
}

export default App;
